import React, { Component } from 'react'

import Button from 'components/CustomButton/CustomButton'
import { Card } from 'components/Card/Card'
import { FormInputs, CustomFormControlProps } from 'components/FormInputs/FormInputs'
import { Grid } from 'react-bootstrap'
import { __, T } from 'config/i18n'
import { Project } from 'types'
import { Projects } from 'api'

interface Props {
  project?: Partial<Project>
}

export default class ProjectDetailTab extends Component<Props> {
  projects = new Projects()
  properties: CustomFormControlProps[] = [
    {
      label: 'id',
      disabled: true,
    },
    {
      ncols: 'col-md-4',
      label: 'macro',
      disabled: true,
      getdefaultvalue: (data: Project) => data.macro?.length.toString(),
    },
    {
      ncols: 'col-md-4',
      label: 'subcategories',
      disabled: true,
      getdefaultvalue: (data: Project) => data.subcategories?.length.toString(),
    },
    {
      ncols: 'col-md-4',
      label: 'tracks',
      disabled: true,
      getdefaultvalue: (data: Project) => data.tracks?.length.toString(),
    },
    {
      label: 'name',
    },
    {
      label: 'version',
    },
    {
      label: 'configuration',
    },
  ]
  onFormSubmit = (e: any) => {
    e.preventDefault()
    this.onSave()
  }

  saveCall = async () => {
    return this.projects.save(this.props.project)
  }
  editCall = async () => {
    return this.projects.edit(this.props.project!.id!, this.props.project)
  }

  onSave = async () => {
    let res
    if (this.props.project!.id) {
      res = await this.editCall()
    } else {
      res = await this.saveCall()
    }
    if (res.success) {
      ;(this.props as any).history.goBack()
    }
  }

  render() {
    const { project } = this.props
    const title = project?.id ? `${__(T.title.project)} "${project!.name}"` : __(T.title.new_project)
    return (
      <Grid fluid>
        <Card
          title={title}
          content={
            project && (
              <form onSubmit={this.onFormSubmit}>
                <FormInputs
                  ncols={[...this.properties!.map((p) => p.ncols || 'col-md-12')]}
                  properties={this.properties}
                  data={project}
                />
                <Button bsStyle="info" pullRight fill type="submit">
                  Salva
                </Button>
                <div className="clearfix" />
              </form>
            )
          }
        />
      </Grid>
    )
  }
}
