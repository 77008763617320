import React, { Component } from 'react'

export interface ViewProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  full?: boolean
  grey?: boolean
  center?: boolean
  spaceBetween?: boolean
  half?: boolean
  className?: string
  style?: any
  row?: boolean
  padding?: string
  margin?: string
  onClick?: (e?: any) => void
}
export default class View extends Component<ViewProps> {
  static defaultProps: Partial<ViewProps> = {
    style: {},
  }
  render() {
    let { full, grey, className, center, style, spaceBetween, half, row, padding, margin, ...props } = this.props
    let preClassNames = ''
    if (full) preClassNames += 'full '
    if (half) preClassNames += 'half '
    if (grey) preClassNames += 'grey '
    if (center) preClassNames += 'center '
    if (spaceBetween) preClassNames += 'spaceBetween '
    if (row) preClassNames += 'flexDirectionRow '
    if (className) preClassNames += ` ${className}`
    if (padding) style = { padding, ...style }
    if (margin) style = { margin, ...style }

    return (
      <div className={`${preClassNames}`} {...props} style={style}>
        {this.props.children}
      </div>
    )
  }
}
