import React from 'react'
import { protectedRoutes, indexRoutes } from 'views'
import { Route } from 'react-router-dom'
import AdminLayout from 'layouts/AdminLayout'
import { Auth } from 'api'

export const CfgRoutes = async () => {
  let loggerUser = await Auth.loggedUser()
  if (loggerUser.success) {
    return {
      routes: protectedRoutes.map((prop, key) => {
        return <Route path={prop.path} render={props => <AdminLayout {...props} />} key={key} />
      }),
      homepage: '/dashboard',
    }
  } else {
    return {
      routes: indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />
      }),
      homepage: '/login',
    }
  }
}
