import BaseResource from './BaseResource'
import { Translation } from 'types'
import { postCall } from 'api/api'

export default class Translations extends BaseResource<any> {
  endpoint = 'v1/translations'

  async saveTranslations(project_id: string, translations: Translation[]) {
    return postCall(this.endpoint + `/save`, { project_id, translations })
  }
}
