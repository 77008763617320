import { AxiosRequestConfig } from 'axios'
import { CustomApiResponse } from 'types'
import { getCall, postCall, putCall, deleteCall } from 'api/api'

export default class BaseResource<T> {
  endpoint?: string
  /**
   * Pagination config (global for all resources)
   */
  defaultPaginationConfig = {
    offset: 0,
    limit: 50,
    orderBy: 'CREATION_DATE',
    asc: true,
  }

  /**
   * Get one Object based on the search params (eg. id: 435)
   *
   * @param {Object} id entity identifier
   * @returns {Object}
   */
  get(id: string, requestConfig?: AxiosRequestConfig): Promise<CustomApiResponse<T>> {
    return getCall<T>(`${this.endpoint}/${id}`, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Search resources
   *
   * @param {Object} params query params
   * @returns {Array}
   */
  search(params?: any, requestConfig?: AxiosRequestConfig): Promise<CustomApiResponse<T[]>> {
    return getCall<T[]>(`${this.endpoint}`, { ...requestConfig, params })
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Search Resources passing params with pagination config.
   *
   * eg. const parcels = await Parcels.paginate({ received: false });
   *
   * By default the pagination config is:
   * {
   *   offset: 0,
   *   limit: 50,
   *   orderBy: 'CREATION_DATE',
   *   asc: true,
   * }
   *
   * @param {Object} searchParams query params
   * @param {Object} paginationConfig pagination config { offset, limit, groupBy, asc }
   * @returns {Array} for the current page
   */
  paginate(searchParams?: any, paginationConfig?: AxiosRequestConfig, requestConfig?: AxiosRequestConfig) {
    const paginationParams = paginationConfig || this.defaultPaginationConfig

    return getCall<T>(`${this.endpoint}`, {
      ...requestConfig,
      params: { ...paginationParams, ...searchParams },
    })
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  configureDefaultPagination(cfg: any, requestConfig?: AxiosRequestConfig) {
    if ((cfg.hasOwnProperty('offset'), requestConfig)) this.defaultPaginationConfig.offset = cfg.offset

    if (cfg.hasOwnProperty('limit')) this.defaultPaginationConfig.limit = cfg.limit

    if (cfg.hasOwnProperty('orderBy')) this.defaultPaginationConfig.orderBy = cfg.orderBy

    if (cfg.hasOwnProperty('asc')) this.defaultPaginationConfig.asc = cfg.asc
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  save(data: any, requestConfig?: AxiosRequestConfig): Promise<CustomApiResponse<T>> {
    return postCall<T>(`${this.endpoint}`, data, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Creates or Updates an entity
   *
   * @param {Object} data entity parameters
   * @returns {Object}
   */
  edit(id: string, data: any, requestConfig?: AxiosRequestConfig): Promise<CustomApiResponse<T>> {
    return putCall<T>(`${this.endpoint}/${id}`, data, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Delete a specific entity
   *
   * @param {String} id
   */
  delete(id: string, requestConfig?: AxiosRequestConfig): Promise<CustomApiResponse<any>> {
    return deleteCall(`${this.endpoint}/${id}`, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Creates many entities
   *
   * @param {Array} data array of entities
   * @returns {Array}
   */
  bulkCreate(data: any, requestConfig?: AxiosRequestConfig) {
    return postCall<T>(`${this.endpoint}/bulkCreate`, data, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }

  /**
   * Updates many entities
   *
   * @param {Array} data array of entities to update
   * @returns {Array}
   */
  bulkUpdate(data: any, requestConfig?: AxiosRequestConfig) {
    return postCall<T>(`${this.endpoint}/bulkUpdate`, data, requestConfig)
      .then(response => response)
      .catch(responseError => responseError.response.data)
  }
}
