import BaseResource from './BaseResource'
import { Media } from 'types'
import Axios from 'axios'
import { urlProxy } from 'config/config'
import { getLocalStorage, AppStore } from 'utils'
import { deleteCall } from 'api/api'

export default class Medias extends BaseResource<Media> {
  static endpoint = 'v1/media'

  static async upload(project_id: string, media: Media) {
    var formData = new FormData()
    !!media.language && formData.append('language', media.language)
    !!media.type && formData.append('type', media.type)
    !!media.mediable_id && formData.append('mediable_id', media.mediable_id)
    !!media.mediable_type && formData.append('mediable_type', media.mediable_type)
    formData.append('media', media.media)
    formData.append('project_id', project_id)

    var bearerToken = 'Bearer ' + getLocalStorage('token')
    let headers = {
      Authorization: bearerToken,
      'Content-Type': 'multipart/form-data',
    }

    let res = await Axios.post<{ data: Media }>(urlProxy + this.endpoint, formData, {
      headers,
    }).then((response) => {
      if (response.status === 200) {
        AppStore.notify(`Media "${media.media.name}" caricato correttamente.`, 'success')
      } else {
        AppStore.notify(`Media "${media.media.name}" non caricato.`, 'error')
      }
      return response
    })
    return res.data.data
    // return res.data.id
  }

  static async delete(media: Media) {
    return await deleteCall(`v1/media/${media.id}`)
  }
}
