import React, { Component } from 'react'
import { Project, Subcategory } from 'types'
import { Grid, Col, Row, Modal } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton'
import { __, T } from 'config/i18n'
import FormInputs, { CustomFormControlProps } from 'components/FormInputs/FormInputs'
import TableView from 'views/common/TableView'
import { Singlecards, Medias } from 'api'
import { View, UploadImageBox } from 'components/Common'
import { normalizeMedia } from 'utils'

interface Props {
  project: Project
  refreshCallback: () => void
}
interface State {
  showModal: boolean
  singlecard: any
  showImageModal: boolean
}

class SingleCardTable extends TableView {
  title = __(T.title.singlecards)
  resource = new Singlecards()
  columns = [{ label: __(T.form.title), name: 'index' }]
  defaultColumns = [this.imageableColumn, this.editableColumn, this.deletableColumn]
}

export default class SingleCardTab extends Component<Props, State> {
  singlecardResource = new Singlecards()
  state: State = {
    showModal: false,
    singlecard: {},
    showImageModal: false,
  }

  properties: CustomFormControlProps[] = [
    {
      label: 'id',
      disabled: true,
    },
    {
      label: 'icon',
      required: true,
    },
    {
      label: 'link',
      required: true,
    },
    // {
    //   label: 'type',
    //   required: true,
    // },
  ]

  onDetailClick = (item?: any) => {
    this.setState({ singlecard: item || {} })
    this.handleShow()
  }

  onImageableClick = (item?: any) => {
    this.setState({ singlecard: item || {}, showImageModal: true })
  }

  handleClose = () => this.setState({ showModal: false })
  handleShow = () => this.setState({ showModal: true })

  saveClose = async () => {
    const { refreshCallback, project } = this.props
    const { singlecard } = this.state

    if (!singlecard) return
    let res
    if (singlecard.id) {
      res = await this.singlecardResource.edit(singlecard.id, singlecard)
    } else {
      singlecard.project_id = project.id!
      res = await this.singlecardResource.save(singlecard)
    }
    if (res.success) {
      await this.setState({ showModal: false })
      await refreshCallback()
    }
  }

  // onCheckPress = (index: number) => {
  //   const { project } = this.props
  //   const { macro } = this.state
  //   if (project.subcategories[index].macro_id === macro.id) {
  //     project.subcategories[index].macro_id = undefined
  //   } else {
  //     project.subcategories[index].macro_id = macro.id
  //   }
  //   this.forceUpdate()
  // }

  // onSubClick = (subSelected: Subcategory) => {
  //   const { project } = this.props
  //   const { macro } = this.state

  //   let subcategory = project.subcategories.find((x) => x.id === subSelected.id)!

  //   if (subcategory.macro_id === macro.id) {
  //     subcategory.macro_id = subcategory.old_macro_id
  //   } else {
  //     subcategory.old_macro_id = subcategory.macro_id
  //     subcategory.macro_id = macro.id
  //   }
  //   this.forceUpdate()
  // }

  onMediaSave = async () => {
    const { project } = this.props
    const { singlecard } = this.state
    let promises = singlecard.media
      ?.filter((m: any) => m.media && typeof m.media !== 'string')
      .map(async (x: any) => {
        x.id = (await Medias.upload(project.id!, x)).id
      })
    promises && (await Promise.all(promises))

    this.setState({ showImageModal: false })
  }

  render() {
    const { project, refreshCallback } = this.props
    const { showModal, singlecard, showImageModal } = this.state

    const editModal = (
      <Modal {...(this.props as any)} show={showModal} dialogClassName={'full-modal'} onHide={this.handleClose}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            this.saveClose()
          }}
        >
          <Modal.Header closeButton onClick={this.handleClose}>
            <Modal.Title id="contained-modal-title-lg">
              {__(T.title.singlecards)} {singlecard.title ? `"${singlecard.title}"` : ''}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <FormInputs
                    data={singlecard}
                    ncols={[...this.properties!.map((p) => p.ncols || 'col-md-12')]}
                    properties={this.properties}
                  />
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="warning" fill onClick={this.handleClose}>
              Chiudi
            </Button>
            <Button bsStyle="info" fill type={'submit'}>
              Salva
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )

    const imageModal = singlecard.media ? (
      <Modal
        dialogClassName="custom-dialog"
        show={showImageModal}
        onHide={() => this.setState({ showImageModal: false })}
      >
        <Modal.Body>
          <UploadImageBox fileType={'jpg'} medias={normalizeMedia(singlecard, 'singlecard', ['main']).media} />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="warning" fill onClick={() => this.setState({ showImageModal: false })}>
            Chiudi
          </Button>
          <Button bsStyle="info" fill type={'submit'} onClick={this.onMediaSave}>
            Salva
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
        <View></View>
      )

    return (
      <>
        <SingleCardTable
          data={project.singlecards}
          onDetailClick={this.onDetailClick}
          title={`${__(T.title.singlecards)} (${project.singlecards.length})`}
          onImageableClick={this.onImageableClick}
          refreshCallback={refreshCallback}
          {...(this.props as any)}
        />
        {editModal}
        {imageModal}
      </>
    )
  }
}
