import React, { Component } from 'react'
import Dropzone, { DropEvent } from 'react-dropzone'
import { View } from '.'

interface Props {
  onFileSelected?<T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent): void
  multiple: boolean,
  fileType: string
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
} as any

export default class CustomDropZone extends Component<Props> {
  static defaultProps: Partial<Props> = {
    multiple: false,
  }
  render() {
    const { multiple, fileType } = this.props
    return (
      <Dropzone multiple={multiple} onDrop={this.props.onFileSelected}>
        {({ getRootProps, getInputProps }) => (
          <View center style={baseStyle} {...getRootProps()}>
            <input {...getInputProps({ accept: '.' + fileType })} />
            <p style={{ textAlign: 'center' }}>Drag 'n' drop i tuoi file di tipo {fileType} qua, altrimenti clicca e seleziona il file.</p>
          </View>
        )}
      </Dropzone>
    )
  }
}
