class AppStore {
  notificationSystem: any // current logged user profile
  currentScreen: string = "D'Uva CMS"

  loadData() {}

  notify = (message: string, level: 'success' | 'error' | 'warning' | 'info' = 'error') => {
    this.notificationSystem &&
      this.notificationSystem.addNotification({
        message,
        level,
      })
  }
}

export default new AppStore()
