import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Projects } from 'api'
import { __, T } from 'config/i18n'
import ProjectDetailTab from './ProjectDetailTab'
import ProjectMacroTab from './ProjectMacroTab'
import ProjectSubcategoryTab from './ProjectSubcategoryTab'
import ProjectTrackTab from './ProjectTrackTab'
import SingleCardTab from './SingleCardTab'
import ExploreCardTab from './ExploreCardTab'

interface Props {}
interface State {
  project: any
  tabIndex: number
}

export default class ProjectView extends Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      project: undefined,
      tabIndex: 0,
    }
  }
  resource = new Projects()

  componentDidMount() {
    this.refreshProject()
  }

  refreshProject = () => {
    const { id } = (this.props as any).match.params
    if (id && id !== 'new') {
      this.resource.get(id).then(({ data }) => {
        this.setState({ project: data })
      })
    } else {
      this.setState({ project: undefined })
    }
  }

  render() {
    const { project } = this.state
    const { id } = (this.props as any).match.params
    if (id === 'new') {
      return <ProjectDetailTab project={{ id: undefined, name: '' }} {...this.props} />
    }
    if (!project) {
      return <></>
    }

    const title = project.id ? `${__(T.title.project)} "${project.name}"` : __(T.title.new_project)
    return (
      <div className="content">
        <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.setState({ tabIndex })}>
          <div style={{ position: 'sticky', top: 0, zIndex: 999, backgroundColor: 'rgb(245,245,246)' }}>
            <TabList>
              <Tab>{title}</Tab>
              <Tab>{__(T.title.macro)}</Tab>
              <Tab>{__(T.title.subcategories)}</Tab>
              <Tab>{__(T.title.tracks)}</Tab>
              <Tab>{__(T.title.singlecards)}</Tab>
              <Tab>{__(T.title.explorecards)}</Tab>
            </TabList>
          </div>
          <TabPanel>
            <ProjectDetailTab project={project} {...this.props} />
          </TabPanel>
          <TabPanel>
            <ProjectMacroTab refreshCallback={this.refreshProject} project={project} {...this.props} />
          </TabPanel>
          <TabPanel>
            <ProjectSubcategoryTab refreshCallback={this.refreshProject} project={project} {...this.props} />
          </TabPanel>
          <TabPanel>
            <ProjectTrackTab refreshCallback={this.refreshProject} project={project} {...this.props} />
          </TabPanel>
          <TabPanel>
            <SingleCardTab refreshCallback={this.refreshProject} project={project} {...this.props} />
          </TabPanel>
          <TabPanel>
            <ExploreCardTab refreshCallback={this.refreshProject} project={project} {...this.props} />
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}
