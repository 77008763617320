/* eslint-disable @typescript-eslint/camelcase */
// prettier-ignore
export default {
    default: {
        it: 'Italiano',
        en: 'English'
    },
    title:{
        dashboard: 'Dashboard',
        projects: 'Projects',
        user_profile: 'User profile',
        translations: 'Traduzioni'
    }
}
