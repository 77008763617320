import React, { Component } from 'react'
import { FormGroup, ControlLabel, FormControl, FormControlProps } from 'react-bootstrap'
import { __ } from 'config/i18n'
import { __DEV__ } from 'utils'
// import { Editor } from '@tinymce/tinymce-react'

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'

const removeString =
  '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>'

if (!__DEV__) {
  /* eslint-disable import/first */
  //@ts-ignore
  import('assets/css/froala-custom.css')
}

//@ts-ignore
import FroalaEditorComponent from 'react-froala-wysiwyg'

export interface CustomFormControlProps extends FormControlProps {
  ncols?: string
  getdefaultvalue?: (data: any) => string
  onCustomChange?: (value: string) => void
  defaultValue?: string
}

interface Props {
  ncols: string[]
  properties?: CustomFormControlProps[]
  data: any
  input: any
}

export class TranslationFormInput extends Component<Props> {
  static defaultProps: Partial<Props> = {
    ncols: [],
  }

  removeString = (field: CustomFormControlProps, value: string) => {
    //@ts-ignore
    field.onCustomChange(value.replace(removeString, ''))
  }

  FieldGroup = (field: CustomFormControlProps) => {
    const { data } = this.props
    const { label, ...props } = field
    const langLabel = __(`form.${label?.toLowerCase()}`)

    let defaultValue
    if (field.defaultValue) {
      defaultValue = field.defaultValue
    } else {
      if (field.getdefaultvalue) {
        defaultValue = field.getdefaultvalue(data)
      } else {
        defaultValue = (data && data[label as any]) || null
      }
    }

    if (!defaultValue) defaultValue = ''

    if (data && !field.onCustomChange) {
      props.onChange = (e) => {
        let value
        if (typeof e !== 'string') {
          //@ts-ignore
          value = e.target.value
        } else {
          value = e
        }
        data[label as any] = value
      }
    }
    if (field.onCustomChange) {
      props.onChange = (e) => {
        let value
        if (typeof e !== 'string') {
          //@ts-ignore
          value = e.target.value
        } else {
          value = e
        }
        //@ts-ignore
        field.onCustomChange(value)
      }
    }

    let formControl
    if (props.componentClass === 'html') {
      let config = {
        events: {
          blur: (e: any, editor: any) => {
            this.removeString(field, e.target.innerHTML)
          },
        },
      }
      formControl = <FroalaEditorComponent tag="textarea" model={defaultValue} config={config} />
      // formControl = (
      //   <Editor
      //     initialValue={defaultValue}
      //     apiKey="x1yuz6olqkky7iqxu44a980ztu5i1vc6z8revrofkggne5e2"
      //     init={{
      //       height: 400,
      //       menubar: false,
      //       plugins: [
      //         'advlist autolink lists link image',
      //         'charmap print preview anchor help',
      //         'searchreplace visualblocks code',
      //         'insertdatetime media table paste wordcount',
      //       ],
      //       toolbar:
      //         'undo redo | formatselect | bold italic | \
      //       alignleft aligncenter alignright | \
      //       bullist numlist outdent indent | help',
      //     }}
      //     //@ts-ignore
      //     onChange={(e) => props.onChange(e.target.getContent())}
      //   />
      // )
    } else {
      formControl = <FormControl {...props} value={defaultValue} placeholder={langLabel} />
    }
    return (
      <FormGroup>
        <ControlLabel>{langLabel || label}</ControlLabel>
        {formControl}
      </FormGroup>
    )
  }

  render() {
    var row = []
    let properties = this.props.properties || []

    for (var i = 0; i < properties.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols.length >= i ? this.props.ncols[i] : 'col-md-3'}>
          {this.FieldGroup(properties[i])}
        </div>
      )
    }
    return row
  }
}

export default TranslationFormInput
