import React from 'react'
import TableView from 'views/common/TableView'
import { __, T } from 'config/i18n'
import { Projects } from 'api'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { MUIDataTableColumnDef } from 'mui-datatables'

export default class ProjectsTableView extends TableView {
  title = __(T.title.projects)
  resource = new Projects()
  defaultColumns: MUIDataTableColumnDef[] = [
    // {
    //   name: 'Clona',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     empty: true,
    //     customHeadRender: this.minWithColumn,
    //     customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
    //       return (
    //         <div style={{ textAlign: 'center' }}>
    //           <FileCopyIcon
    //             style={{ fontSize: 20 }}
    //             onClick={async () => {
    //               await new Projects().clone(this.state.data![tableMeta.rowIndex].id)
    //               // window.location.reload()
    //             }}
    //           />
    //         </div>
    //       )
    //     },
    //   },
    // },
    {
      name: 'Esporta',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customHeadRender: this.minWithColumn,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <GetAppIcon
                style={{ fontSize: 20 }}
                onClick={() => {
                  new Projects().export(this.state.data![tableMeta.rowIndex].id)
                }}
              />
            </div>
          )
        },
      },
    },
    {
      name: 'Modifica',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customHeadRender: this.minWithColumn,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <EditIcon
                style={{ fontSize: 20 }}
                onClick={() => this.openEditPage(this.state.data![tableMeta.rowIndex])}
              />
            </div>
          )
        },
      },
    },
  ]
}
