import { createBrowserHistory } from 'history'
import { stringify, parse } from 'qs'

interface QueryParams {
  [key: string]: any
}

interface NavigationOptions {
  replace?: boolean
  props?: { [key: string]: any }
}

// navigation history
export const history = createBrowserHistory()

export function navigate(_path: string, queryParams?: QueryParams, options?: NavigationOptions): void {
  // add query params to path
  const stringParams = queryParams ? stringify(queryParams, { arrayFormat: 'repeat' }) : ''
  const path = `${_path}${stringParams ? `?${stringParams}` : ''}`
  const props = options ? options.props : undefined

  options && options.replace ? history.replace(path, props) : history.push(path, props)
}

export function navigateBack(): void {
  history.goBack()
}

export function getQueryParams(): Record<string, any> {
  const search = history.location.search
  const queryParams = search && search.charAt(0) === '?' ? search.substring(1) : search
  return parse(queryParams)
}
