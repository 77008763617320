import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { CustomApiResponse } from 'types'
import { getLocalStorage, AppStore } from 'utils'
import { urlProxy } from 'config/config'

const catchError = (error: any) => {
  AppStore.notify(error.data?.message || JSON.stringify(error))
  if (error.response) return error.response.data
  else return { success: false }
}

const getResponse = (response: AxiosResponse<any>) => {
  return response.data
}

export const headerAuthorization = () => {
  var bearerToken = 'Bearer ' + getLocalStorage('token')
  return {
    Authorization: bearerToken,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }
}

export const putCall = async <T>(
  endpoint: string,
  data: any,
  requestConfig?: AxiosRequestConfig
): Promise<CustomApiResponse<T>> => {
  return Axios.put<T>(urlProxy + endpoint, JSON.stringify(data), {
    headers: headerAuthorization(),
    ...requestConfig,
  })
    .then((response) => getResponse(response))
    .catch((responseError) => catchError(responseError))
}

export const postCall = async <T>(
  endpoint: string,
  data?: any,
  requestConfig?: AxiosRequestConfig
): Promise<CustomApiResponse<T>> => {
  return Axios.post<T>(urlProxy + endpoint, JSON.stringify(data), {
    headers: headerAuthorization(),
    ...requestConfig,
  })
    .then((response) => getResponse(response))
    .catch((responseError) => catchError(responseError))
}

export const getCall = async <T>(
  endpoint: string,
  requestConfig?: AxiosRequestConfig
): Promise<CustomApiResponse<T>> => {
  return Axios.get<T>(urlProxy + endpoint, {
    headers: headerAuthorization(),
    ...requestConfig,
  })
    .then((response) => getResponse(response))
    .catch((responseError) => catchError(responseError))
}

export const deleteCall = async <T>(
  endpoint: string,
  requestConfig?: AxiosRequestConfig
): Promise<CustomApiResponse<T>> => {
  return Axios(urlProxy + endpoint, {
    method: 'DELETE',
    headers: headerAuthorization(),
    ...requestConfig,
  })
    .then((response) => getResponse(response))
    .catch((responseError) => catchError(responseError))
}
