import React, { Component } from 'react'

import { View } from '.'
import { urlProxy } from 'config/config'
import { ViewProps } from './View'

interface Props {
  src?: string
  height?: string
  id?: string
  style?: any
  onClick?: () => void
}

export default class PreviewImage extends Component<Props> {
  endpoint = 'v1/media'
  static defaultProps: Partial<Props> = {
    height: '400px',
  }
  render() {
    const { id, height, src, ...rest } = this.props
    let srcX = ''
    if (id) srcX = `${urlProxy}${this.endpoint}/${id}`
    if (src) srcX = src
    return (
      <View full padding={'10px'} {...rest}>
        <img alt={src} style={{ width: '100%', height: height, objectFit: 'contain' }} src={srcX} />
      </View>
    )
  }
}
