import React, { Component } from 'react'

interface Props {
  key?: string | number
  title: string
  onClick: () => void
  checked: boolean
}

export default class Checkbox extends Component<Props> {
  render() {
    const { key, title, onClick, checked } = this.props
    return (
      <div key={key} className="checkbox" onClick={() => onClick()}>
        <input type="checkbox" checked={checked} />
        <label>{title}</label>
      </div>
    )
  }
}
