/* eslint-disable @typescript-eslint/camelcase */
// prettier-ignore
export default {
    default: {
        it: 'Italiano',
        en: 'English'
    },
    title:{
        dashboard: 'Dashboard',
        projects: 'Progetti',
        project: 'Progetto',
        user_profile: 'Profilo utente',
        track: 'Traccia',
        tracks: 'Tracce',
        subcategory: 'Categoria',
        subcategories: 'Categorie',
        macro: 'Macro',
        new_project: 'Nuovo progetto',
        translations: 'Traduzioni',
        media: 'Media',
        singlecards: 'Single Cards',
        explorecards: 'Explore Cards'
    },
    form:{
        id: 'ID',
        name:'Nome',
        version: 'Versione',
        title: 'Titolo',
        username: 'Nome utente',
        password: 'Password',
        subtitle: 'Sotto titolo',
        fatherindex: 'FatherIndex',
        image: 'Immagine',
        index: 'Index',
        type: 'Tipo',
        active: 'Attivo',
        description:'Descrizione',
        image_ref: 'Immagine',
        image_vetrina_ref: 'Immagine Vetrina',
        store: 'Store',
        cluster: 'Cluster',
        status: 'Stato',
        created_at: 'Data creazione',
        updated_at: 'Data aggiornamento',
        project_id: 'Progetto',
        subcategories: 'Categorie',
        tracks: 'Tracce',
        macro: 'Macro',
        description_1: 'Descrizione 1',
        description_2: 'Descrizione 2',
        visual_index: 'Visual Index',
        search_index: 'Search Index',
        text: 'Testo',
        texthtml: 'Testo (HTML)'
    },
    misc: {
        translate: 'Traduci'
    }
}
