import React, { Component } from 'react'
import { Grid } from 'react-bootstrap'
import DataTable, { MUIDataTableOptions, MUIDataTableColumn, MUIDataTableColumnDef } from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ImageIcon from '@material-ui/icons/Image'
import { TableCell } from '@material-ui/core'
import BaseResource from 'api/resources/BaseResource'
import { __ } from 'config/i18n'

interface Props {
  history: any
  title?: string
  onDetailClick?: (item?: any) => void
  data?: any[]
  onImageableClick?: (item?: any) => void
  refreshCallback?: () => void
}
interface State {
  data?: any[]
}

export default class TableView extends Component<Props, State> {
  state: State = {
    data: this.props.data || undefined,
  }
  title = 'Title'
  resource!: BaseResource<any>
  fontSize = { fontSize: '8pt' }
  options: MUIDataTableOptions = {
    selectableRows: 'none',
  }
  columns: MUIDataTableColumnDef[] = []

  plusFab = true

  minWithColumn = ({ index, ...column }: any) => {
    return (
      <TableCell key={index} style={{ width: 10 }}>
        {column.label}
      </TableCell>
    )
  }

  imageableColumn: MUIDataTableColumnDef = {
    name: 'Immagini',
    options: {
      filter: false,
      sort: false,
      empty: true,
      customHeadRender: this.minWithColumn,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ImageIcon
              style={{ fontSize: 20 }}
              onClick={() =>
                this.props.onImageableClick && this.props.onImageableClick(this.state.data![tableMeta.rowIndex])
              }
            />
          </div>
        )
      },
    },
  }

  editableColumn: MUIDataTableColumnDef = {
    name: 'Modifica',
    options: {
      filter: false,
      sort: false,
      empty: true,
      customHeadRender: this.minWithColumn,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <EditIcon
              style={{ fontSize: 20 }}
              onClick={() => this.openEditPage(this.state.data![tableMeta.rowIndex])}
            />
          </div>
        )
      },
    },
  }

  deletableColumn: MUIDataTableColumnDef = {
    name: 'Cancella',
    options: {
      filter: false,
      sort: false,
      empty: true,
      customHeadRender: this.minWithColumn,
      customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <DeleteIcon
              style={{ fontSize: 20 }}
              onClick={async () => {
                const { refreshCallback } = this.props
                await this.resource.delete(this.state.data![tableMeta.rowIndex].id)
                // this.loadData()
                refreshCallback && (await refreshCallback())
              }}
            />
          </div>
        )
      },
    },
  }

  defaultColumns: MUIDataTableColumnDef[] = [this.editableColumn, this.deletableColumn]

  hideColumns = ['id', 'project_id', 'created_at', 'updated_at']

  createColumns = (columns: string[]) => {
    if (this.columns.length > 0) return
    this.columns = columns.map((c) => {
      let display: 'true' | 'false' | 'excluded' | undefined = 'true'
      if (this.hideColumns.filter((hC) => hC === c).length > 0) {
        display = 'false'
      }
      let column: MUIDataTableColumn = {
        label: __(`form.${c}`),
        name: c,
        options: {
          display,
        },
      }

      return column
    })
  }

  loadData = async (): Promise<any> => {
    let response = await this.resource.search()
    if (response.success && response.data && response.data.length > 0) {
      const { data } = response
      this.createColumns(Object.keys(data[0]))
      this.setState({ data })
    } else {
      this.setState({ data: [] })
    }
  }

  componentDidMount() {
    const { data } = this.state
    if (!data) {
      this.loadData()
    } else {
      if (data.length > 0) {
        this.createColumns(Object.keys(data[0]))
        this.forceUpdate()
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.data && prevProps.data?.length !== this.props.data.length) {
      this.setState({ data: this.props.data })
    }
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: { root: { lineHeight: 0, ...this.fontSize } },
        MuiSvgIcon: { root: {} },
        MuiTablePagination: { caption: this.fontSize },
        MuiSelect: { select: this.fontSize },
        MuiTableBody: { root: {} },
      },
    })

  openEditPage = (item?: any) => {
    const { onDetailClick } = this.props
    if (onDetailClick) {
      onDetailClick(item)
      return
    }
    let path = window.location.pathname
    this.props.history.push(`${path.substr(0, path.length - 1)}/${item ? item.id : 'new'}`)
  }

  render() {
    const { data } = this.state
    const { title } = this.props

    return (
      <div className="content">
        <Grid fluid>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <DataTable
              title={title || this.title}
              data={data || []}
              columns={[...this.columns, ...this.defaultColumns]}
              options={this.options}
            />
          </MuiThemeProvider>
        </Grid>
        {this.plusFab && (
          <div className="floating-button text-primary">
            <AddCircleRoundedIcon onClick={() => this.openEditPage()} style={{ fontSize: 40 }} />
          </div>
        )}
      </div>
    )
  }
}
