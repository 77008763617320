import React, { Component } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

interface Props {
  onChange:
    | ((
        event: React.ChangeEvent<{
          name?: string | undefined
          value: unknown
        }>,
        child: React.ReactNode
      ) => void)
    | undefined
  value: string
}

export default class LanguageSelector extends Component<Props> {
  render() {
    const { onChange, value } = this.props
    return (
      <FormControl style={{ minWidth: '100px' }}>
        <InputLabel>Lingua</InputLabel>
        <Select id="demo-simple-select-filled" value={value || 'it'} onChange={onChange}>
          <MenuItem value={'it'}>Italiano</MenuItem>
          <MenuItem value={'en'}>Inglese</MenuItem>
          <MenuItem value={'fr'}>Francese</MenuItem>
          <MenuItem value={'de'}>Tedesco</MenuItem>
          <MenuItem value={'sp'}>Spagnolo</MenuItem>
          <MenuItem value={'ci'}>Cinese</MenuItem>
          <MenuItem value={'jp'}>Giapponese</MenuItem>
          <MenuItem value={'ru'}>Russo</MenuItem>
          <MenuItem value={'pt'}>Portoghese</MenuItem>
          <MenuItem value={'ko'}>Coreano</MenuItem>
          <MenuItem value={'po'}>Polacco</MenuItem>
          <MenuItem value={'ar'}>Arabo</MenuItem>
          <MenuItem value={'la'}>Latino</MenuItem>
          <MenuItem value={'nl'}>Olandese</MenuItem>
          <MenuItem value={'uk'}>Ucraino</MenuItem>
        </Select>
      </FormControl>
    )
  }
}
