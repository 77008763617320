import React, { Component } from 'react'
import { Project, EntityProperty, Translation, Media, Entity } from 'types'
import { Projects, Translations } from 'api'
import { navigate } from 'utils/navigator'
import TranslationsCard from './TranslationsCard'
import SaveRoundedIcon from '@material-ui/icons/SaveRounded'
import { __, T } from 'config/i18n'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import LanguageSelector from 'components/Common/LanguageSelector'
import { AppStore } from 'utils'

interface Props {}

interface State {
  project?: Project
  tabIndex: number
  language: string
}

export default class TranslationsView extends Component<Props, State> {
  state: State = {
    project: undefined,
    tabIndex: 0,
    language: 'it',
  }
  constructor(props: Props) {
    super(props)
  }

  id: string = (this.props as any).match.params.id

  async componentDidMount() {
    let { data, success } = await new Projects().get(this.id)
    if (success) {
      this.setState({ project: data })
    } else {
      navigate('/translations')
    }
  }

  onChangeLanguage = (e: any) => {
    this.setState({ language: e.target.value as string })
  }

  findTranslation = (t: Translation, id: any, entity: any, language: any, property: any) => {
    return t.entity === entity && t.external_id === id && t.language === language && t.type === property
  }

  onChangeTranslation = (value: string, property: EntityProperty, id: string, entity: Entity) => {
    const { language, project } = this.state
    let saved = false
    project!.translations.map((t) => {
      if (this.findTranslation(t, id, entity, language, property)) {
        t.translation = value
        saved = true
      }
      return t
    })
    if (!saved) {
      project!.translations.push({
        entity: entity,
        external_id: id,
        language: language,
        type: property,
        translation: value,
      })
    }
    this.setState({ project })
  }

  saveTranslations = async () => {
    const { project } = this.state
    if ((await new Translations().saveTranslations(project!.id!, project!.translations))?.success) {
      AppStore.notify('Salvato correttamente', 'success')
    } else {
      AppStore.notify('Errore durante il salvataggio', 'error')
    }
  }

  getDefaultValue = (id: any, entity: any, property: any) => {
    const { project, language } = this.state
    return project?.translations.find((t) => this.findTranslation(t, id, entity, language, property))?.translation || ''
  }

  onMediaChange = (id: string, language: string, media: Media) => {
    const { project } = this.state

    project!.tracks = project!.tracks.map((t) => {
      if (t.id === id) {
        let saved = false
        t.media = t.media.map((m) => {
          if (m.type === 'audio' && m.language === language) {
            m = { ...media }
          }
          return m
        })
        if (!saved) {
          t.media.push({ ...media })
        }
      }
      return t
    })

    this.setState({ project })
  }

  render() {
    const { project, language } = this.state
    if (!project) return <></>

    return (
      <>
        <div className="content">
          <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.setState({ tabIndex })}>
            <div style={{ position: 'sticky', top: 0, zIndex: 999, backgroundColor: 'rgb(245,245,246)' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '0px 20px',
                }}
              >
                <h4 className="title" style={{ margin: '0px', paddingLeft: '10px', marginBottom: '5px' }}>{`${__(
                  T.title.translations
                )} - ${project.name}`}</h4>
                <LanguageSelector value={language} onChange={this.onChangeLanguage} />
              </div>
              <TabList>
                <Tab>{__(T.title.macro)}</Tab>
                <Tab>{__(T.title.subcategories)}</Tab>
                <Tab>{__(T.title.tracks)}</Tab>
                <Tab>{__(T.title.singlecards)}</Tab>
                <Tab>{__(T.title.explorecards)}</Tab>
                <Tab>{__(T.title.media)}</Tab>
              </TabList>
            </div>
            <TabPanel>
              <TranslationsCard
                entity={'macro'}
                language={language}
                title={__(T.title.macro)}
                properties={['title', 'subtitle', 'description']}
                data={project.macro}
                getDefaultValue={this.getDefaultValue}
                onChangeField={this.onChangeTranslation}
              />
            </TabPanel>
            <TabPanel>
              <TranslationsCard
                entity={'subcategory'}
                language={language}
                title={__(T.title.subcategories)}
                properties={['title', 'subtitle']}
                data={project.subcategories}
                onChangeField={this.onChangeTranslation}
                getDefaultValue={this.getDefaultValue}
              />
            </TabPanel>
            <TabPanel>
              <TranslationsCard
                entity={'track'}
                project_id={project.id}
                language={language}
                title={__(T.title.tracks)}
                properties={['title', 'subtitle', 'description_1', 'description_2']}
                data={project.tracks}
                onChangeField={this.onChangeTranslation}
                getDefaultValue={this.getDefaultValue}
                onMediaChange={this.onMediaChange}
              />
            </TabPanel>
            <TabPanel>
              <TranslationsCard
                entity={'singlecard'}
                project_id={project.id}
                language={language}
                title={__(T.title.singlecards)}
                properties={['title', 'text']}
                data={project.singlecards}
                onChangeField={this.onChangeTranslation}
                getDefaultValue={this.getDefaultValue}
                onMediaChange={this.onMediaChange}
              />
            </TabPanel>
            <TabPanel>
              <TranslationsCard
                entity={'explorecard'}
                project_id={project.id}
                language={language}
                title={__(T.title.explorecards)}
                properties={['title', 'subtitle', 'texthtml']}
                data={project.explorecards}
                onChangeField={this.onChangeTranslation}
                getDefaultValue={this.getDefaultValue}
                onMediaChange={this.onMediaChange}
              />
            </TabPanel>
            <TabPanel>
              <TranslationsCard
                entity={'media'}
                project_id={project.id}
                language={language}
                title={__(T.title.media)}
                properties={['title', 'subtitle']}
                data={project.tracks
                  .map((t) => t.media)
                  .flat()
                  .filter((m) => m.type?.includes('multi'))}
                onChangeField={this.onChangeTranslation}
                getDefaultValue={this.getDefaultValue}
              />
            </TabPanel>
          </Tabs>
        </div>

        <div className="floating-button text-primary">
          <SaveRoundedIcon onClick={this.saveTranslations} style={{ fontSize: 40 }} />
        </div>
      </>
    )
  }
}
