import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import NotificationSystem from 'react-notification-system'

import AdminNavbar from 'components/Navbars/AdminNavbar'
import Footer from 'components/Footer/Footer'
import Sidebar from 'components/Sidebar/Sidebar'

import routes from 'routes.js'

import image from 'assets/img/sidebar-3.jpg'
import { protectedRoutes } from 'views'

interface Props {
  location?: any
}

interface State {
  _notificationSystem?: any
  fixedClasses?: any
  image?: any
  color?: any
  hasImage?: any
  mini?: any
}
export default class AdminLayout extends Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.state = {
      _notificationSystem: null,
      image: image,
      color: 'black',
      hasImage: true,
      fixedClasses: 'dropdown show-dropdown open',
    }
  }
  getRoutes = () => {
    return protectedRoutes.map((prop: any, key: any) => {
      if (!!prop.path) {
        return <Route path={prop.path} component={prop.component} key={key} />
      } else {
        return <></>
      }
    })
  }
  getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name
      }
    }
    return "D'Uva CMS"
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem })
  }
  componentDidUpdate(e: any) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0
      document.scrollingElement!.scrollTop = 0
      ;(this.refs.mainPanel as any).scrollTop = 0
    }
  }
  render() {
    return (
      <div className="wrapper">
        <NotificationSystem
          ref="notificationSystem"
          // style={style}
        />
        <Sidebar
          {...this.props}
          routes={routes}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
        />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar {...this.props} brandText={this.getBrandText()} />
          <Switch>
            <div className="mainContainer">{this.getRoutes()}</div>
          </Switch>
          <Footer />
        </div>
      </div>
    )
  }
}
