import React from 'react'
import TableView from 'views/common/TableView'
import { __, T } from 'config/i18n'
import { Projects } from 'api'
import { MUIDataTableColumnDef } from 'mui-datatables'
import TranslateIcon from '@material-ui/icons/Translate'

export default class TranslationsTableView extends TableView {
  title = `${__(T.title.translations)} - ${__(T.title.projects)}`
  resource = new Projects()
  plusFab = false

  onTranslationPress = (tableMeta: { rowIndex: number }) => {
    const { data } = this.state
    this.props.history.push(`translation/${data![tableMeta.rowIndex].id}`)
  }

  defaultColumns: MUIDataTableColumnDef[] = [
    {
      name: __(T.misc.translate),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customHeadRender: this.minWithColumn,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div style={{ textAlign: 'center' }}>
              <TranslateIcon style={{ fontSize: 20 }} onClick={() => this.onTranslationPress(tableMeta)} />
            </div>
          )
        },
      },
    },
  ]
}
