import { User } from 'types'
import { saveLocalStorage } from 'utils'
import { postCall, getCall } from 'api/api'

export default class Auth {
  static logout() {
    saveLocalStorage('token', '')
    window.location.reload()
  }

  static login(email: string, password: string) {
    let data = {
      email,
      password,
    }

    return postCall<User>('login', data)
  }

  static loggedUser() {
    return getCall<User>('v1/loggedUser')
  }
}
