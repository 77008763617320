import React, { Component } from 'react'
import { Project, Track } from 'types'
import { Grid, Col, Row, Modal } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton'
import { __, T } from 'config/i18n'
import FormInputs, { CustomFormControlProps } from 'components/FormInputs/FormInputs'
import TableView from 'views/common/TableView'
import { Subcategories } from 'api'
import { Subcategory } from 'types'
import { Checkbox } from 'components/Common'
import { FormGroup } from '@material-ui/core'

interface Props {
  project: Project
  refreshCallback: () => void
}
interface State {
  showModal: boolean
  subcategory: Partial<Subcategory>
}

class SubcategoryTable extends TableView {
  title = __(T.title.subcategories)
  resource = new Subcategories()
  columns = [{ label: __(T.form.title), name: 'title' }]
}

export default class ProjectSubcategoryTab extends Component<Props, State> {
  SubcategoryResource = new Subcategories()
  state: State = {
    showModal: false,
    subcategory: {},
  }

  properties: CustomFormControlProps[] = [
    {
      label: 'id',
      disabled: true,
    },
    {
      label: 'title',
      required: true,
    },
  ]

  onDetailClick = (item?: Subcategory) => {
    this.setState({ subcategory: item || {} })
    this.handleShow()
  }

  handleClose = async () => await this.setState({ showModal: false })
  handleShow = () => this.setState({ showModal: true })

  saveClose = async () => {
    const { refreshCallback, project } = this.props
    const { subcategory } = this.state

    if (!subcategory) return
    let res
    subcategory.tracks = project.tracks.filter((track) => track.subcategory_id === subcategory.id).map((x) => x.id!)
    if (subcategory.id) {
      res = await this.SubcategoryResource.edit(subcategory.id, subcategory)
    } else {
      subcategory.project_id = project.id!
      res = await this.SubcategoryResource.save(subcategory)
    }
    if (res.success) {
      await this.setState({ showModal: false })
      await refreshCallback()
    }
  }

  onTrackClick = (trackSelected: Track) => {
    const { project } = this.props
    const { subcategory } = this.state

    let track = project.tracks.find((x) => x.id === trackSelected.id)!

    if (track.subcategory_id === subcategory.id) {
      track.subcategory_id = track.old_subcategory_id
    } else {
      track.old_subcategory_id = track.subcategory_id
      track.subcategory_id = subcategory.id
    }

    this.forceUpdate()
  }

  render() {
    const { refreshCallback, project } = this.props
    const { showModal, subcategory } = this.state

    return (
      <>
        <SubcategoryTable
          data={project.subcategories}
          onDetailClick={this.onDetailClick}
          title={`${__(T.title.subcategories)} (${project.subcategories.length})`}
          refreshCallback={refreshCallback}
          {...(this.props as any)}
        />
        <Modal {...(this.props as any)} show={showModal} dialogClassName={'full-modal'} onHide={this.handleClose}>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              this.saveClose()
            }}
          >
            <Modal.Header closeButton onClick={this.handleClose}>
              <Modal.Title id="contained-modal-title-lg">
                {__(T.title.subcategory)} {subcategory.title ? `"${subcategory.title}"` : ''}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid fluid>
                <Row>
                  <Col md={6}>
                    <FormInputs
                      data={subcategory}
                      ncols={[...this.properties!.map((p) => p.ncols || 'col-md-12')]}
                      properties={this.properties}
                    />
                  </Col>
                  <Col md={6}>
                    {subcategory.id && (
                      <FormGroup>
                        {__(T.title.tracks)}
                        {project.tracks.map((track, index) => (
                          <Checkbox
                            key={index}
                            title={track.title}
                            checked={track.subcategory_id === subcategory.id}
                            onClick={() => this.onTrackClick(track)}
                          />
                        ))}
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </Grid>
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="warning" fill onClick={this.handleClose}>
                Chiudi
              </Button>
              <Button bsStyle="info" fill type={'submit'}>
                Salva
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}
