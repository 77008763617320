import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { Switch, Redirect, Router } from 'react-router-dom'

import 'react-tabs/style/react-tabs.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0'
import './assets/css/demo.css'
import './assets/css/pe-icon-7-stroke.css'
import './assets/css/custom.css'

import { CfgRoutes } from 'views/Routing'
import NotificationSystem from 'react-notification-system'
import { AppStore } from 'utils'
import { history } from 'utils/navigator'

interface Props {}
interface State {
  cfgRoute?: {
    routes: JSX.Element[]
    homepage: string
  }
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { cfgRoute: undefined }
    CfgRoutes().then(cfgRoute => this.setState({ cfgRoute }))
  }

  render() {
    const { cfgRoute } = this.state
    if (!cfgRoute) {
      return <></>
    }
    return (
      <Router history={history}>
        <NotificationSystem ref={(r: any) => (AppStore.notificationSystem = r)} />
        <Switch>
          {cfgRoute.routes}
          <Redirect from="*" to={cfgRoute.homepage} />
        </Switch>
      </Router>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
