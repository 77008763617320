import Dashboard from './Dashboard'
import LoginView from './LoginView'
import ProjectsTableView from './projects/ProjectsTableView'
import ProjectView from './projects/ProjectView'
import TranslationsTableView from './translations/TranslationsTableView'
import TranslationsView from './translations/TranslationsView'

export const indexRoutes = [{ path: '/login', component: LoginView }]

export const protectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/projects', component: ProjectsTableView },
  { path: '/project/:id', component: ProjectView, title: 'project' },
  { path: '/translations', component: TranslationsTableView },
  { path: '/translation/:id', component: TranslationsView },
]
