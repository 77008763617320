import BaseResource from './BaseResource'
import { Project } from 'types'
import { postCall, getCall, headerAuthorization } from 'api/api'
import { urlProxy, urlBackend } from 'config/config'
import { AppStore } from 'utils'

export default class Projects extends BaseResource<Project> {
  endpoint = 'v1/projects'

  changeTrackQuantity(id: string, quantity: number) {
    return postCall(this.endpoint + `/${id}/addTracks`, { quantity })
  }

  clone(id: string) {
    return getCall(this.endpoint + `/${id}/clone`)
  }

  export(id: string) {
    AppStore.notify('Creazione resources.zip in corso..', 'info')
    fetch(urlProxy + this.endpoint + `/${id}/export`, { headers: headerAuthorization() }).then((response) => {
      if (response.status === 200) {
        const urlResources = urlBackend + 'resources.zip'
        AppStore.notify(
          'Creazione resources.zip completata con successo. Inizio download da ' + urlResources,
          'success'
        )
        window.open(urlResources, '_blank')
        // response.blob().then((blob) => {
        //   let url = window.URL.createObjectURL(blob)
        //   let a = document.createElement('a')
        //   a.href = url
        //   a.download = 'resources.zip'
        //   a.click()
        // })
      } else {
        AppStore.notify('Errore durante la creazione del resources.zip', 'error')
      }
      //window.location.href = response.url;
    })
  }
}
