import React, { Component } from 'react'
import { Grid, Image, Row, Col } from 'react-bootstrap'

import { Card } from 'components/Card/Card'
import { FormInputs } from 'components/FormInputs/FormInputs'
import Button from 'components/CustomButton/CustomButton'
import logo from 'assets/img/logo.png'
import { saveLocalStorage } from 'utils'
import { Auth } from 'api'
import Appstore from 'utils/AppStore'

interface State {
  username: string
  password: string
}

export default class LoginView extends Component {
  notificationSystem: any

  state: State = {
    username: '',
    password: '',
  }

  doLogin = async (e: any) => {
    e.preventDefault()
    const { username, password } = this.state
    let res = await Auth.login(username, password)

    if (res.success && res.data) {
      saveLocalStorage('token', res.data.token)
      window.location.reload()
    } else {
      Appstore.notify(res.message || 'Errore chiamata', 'error')
    }
  }

  render() {
    return (
      <div className="container wrapper" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Grid fluid>
          <Row>
            <Col lg={12} sm={12} xs={12}>
              <Card
                hCenter
                title={<Image src={logo} style={{ width: '50%' }} />}
                content={
                  <form onSubmit={this.doLogin}>
                    <FormInputs
                      ncols={['col-md-6', 'col-md-6']}
                      properties={[
                        {
                          label: 'Username',
                          type: 'text',
                          bsClass: 'form-control',
                          placeholder: 'Username',
                          required: true,
                          value: this.state.username,
                          onChange: (e: any) => this.setState({ username: e.target.value }),
                        },
                        {
                          label: 'Password',
                          type: 'password',
                          autoComplete: 'on',
                          bsClass: 'form-control',
                          placeholder: 'Password',
                          required: true,
                          value: this.state.password,
                          onChange: (e: any) => this.setState({ password: e.target.value }),
                        },
                      ]}
                    />
                    <Button bsStyle="info" pullRight fill type="submit">
                      Login
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}
