import BaseResource from './BaseResource'
import { Track } from 'types'
import { putCall } from 'api/api'

export default class Tracks extends BaseResource<Track> {
  endpoint = 'v1/tracks'

  changeIndexValue(id?: string, index?: string, visual_index?: string, search_index?: string) {
    return putCall(this.endpoint + `/${id}`, { id, index, visual_index, search_index })
  }
}
