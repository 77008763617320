import React, { Component } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import cx from 'classnames'

interface Props extends ButtonProps {
  fill?: boolean
  simple?: boolean
  pullRight?: boolean
  round?: boolean
  block?: boolean
}

class CustomButton extends Component<Props> {
  render() {
    const { fill, simple, pullRight, round, block, ...rest } = this.props

    const btnClasses = cx({
      'btn-fill': fill,
      'btn-simple': simple,
      'pull-right': pullRight,
      'btn-block': block,
      'btn-round': round,
    })

    return <Button className={btnClasses} {...rest} />
  }
}

export default CustomButton
