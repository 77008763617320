import React, { Component } from 'react'
import { Navbar } from 'react-bootstrap'

import AdminNavbarLinks from './AdminNavbarLinks'

interface Props {
  brandText?: any
}

interface State {
  sidebarExists?: any
}
class Header extends Component<Props, State> {
  constructor(props: any) {
    super(props)
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this)
    this.state = {
      sidebarExists: false,
    }
  }
  mobileSidebarToggle(e: any) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      })
    }
    e.preventDefault()
    document.documentElement.classList.toggle('nav-open')
    var node: any = document.createElement('div')
    node.id = 'bodyClick'
    node.onclick = function() {
      this.parentElement.removeChild(this)
      document.documentElement.classList.toggle('nav-open')
    }
    document.body.appendChild(node)
  }
  render() {
    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <a href="/">{this.props.brandText}</a>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header
