import Dashboard from 'views/Dashboard.jsx'
import { __, T } from 'config/i18n'
import ProjectsTableView from 'views/projects/ProjectsTableView'

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: __(T.title.dashboard),
    icon: 'pe-7s-home',
    component: Dashboard,
    layout: '',
  },
  {
    path: '/projects',
    name: __(T.title.projects),
    icon: 'pe-7s-news-paper',
    component: ProjectsTableView,
    layout: '',
  },
  {
    path: '/translations',
    name: __(T.title.translations),
    icon: 'fa fa-language',
    component: ProjectsTableView,
    layout: '',
  },
]

export default dashboardRoutes
