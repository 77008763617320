import React, { Component } from 'react'

import { Card } from 'components/Card/Card'
import { TranslationFormInput, CustomFormControlProps } from 'components/FormInputs/TranslationFormInput'
import { Grid } from 'react-bootstrap'
import { Entity, EntityProperty, Track, Media } from 'types'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import VideoIcon from '@material-ui/icons/Videocam'
import DescriptionIcon from '@material-ui/icons/Description'
import { View, PreviewImage } from 'components/Common'
import { Medias } from 'api'
import { urlProxy } from 'config/config'
import { AppStore } from 'utils'

interface Props {
  getDefaultValue: (id: any, entity: any, property: any) => string | undefined
  properties: EntityProperty[]
  data: any[]
  title: string
  language: string
  project_id?: string
  entity: Entity
  onChangeField: (value: string, property: EntityProperty, id: string, entity: Entity) => void
  onMediaChange?: (id: string, language: string, media: Media) => void
}

interface State {
  showDescription?: number
}

export default class TranslationsCard extends Component<Props, State> {
  state: State = {
    showDescription: undefined,
  }

  fileSelector: any
  selectedId?: string

  buildFileSelector = () => {
    const fileSelector = document.createElement('input')
    fileSelector.setAttribute('type', 'file')
    fileSelector.setAttribute('file-accept', 'mp3 mp4')
    fileSelector.onchange = () => this.onFileSelected(fileSelector)
    return fileSelector
  }

  onFileSelected = async (fileSelector: HTMLInputElement) => {
    const { language, project_id, onMediaChange } = this.props
    if (fileSelector.files?.length === 0) {
      return
    }
    if (!fileSelector.files![0].name.includes('.mp3') && !fileSelector.files![0].name.includes('.mp4')) {
      return AppStore.notify('Selezionare un file .mp3 o .mp4', 'error')
    }
    let mediaUploaded = await Medias.upload(project_id!, {
      language,
      media: fileSelector.files![0],
      mediable_id: this.selectedId,
      mediable_type: 'track',
      type: fileSelector.files![0].name.includes('.mp3') ? 'audio' : 'video',
      project_id: project_id,
    })

    if (onMediaChange) onMediaChange(this.selectedId!, language, mediaUploaded)
    AppStore.notify(`Media ${mediaUploaded.path} caricato correttamente`, 'success')
    this.selectedId = undefined
  }

  componentDidMount() {
    this.fileSelector = this.buildFileSelector()
  }

  handleFileSelect = (e: any, id: string) => {
    e.preventDefault()
    this.selectedId = id
    this.fileSelector.click()
  }

  onFormSubmit = (e: any) => {
    e.preventDefault()
    this.onSave()
  }

  onSave = async () => {}

  onCustomChange = (value: string, type: EntityProperty, id: string) => {
    const { onChangeField, entity } = this.props
    onChangeField(value, type, id, entity)
  }

  uploadMedia = (id: string, file?: any) => {}

  playMedia = (track: Track) => {
    const { language } = this.props
    let audio_id = track.media?.find((m: any) => (m.type === 'audio' || m.type === 'video') && m.language === language)
      ?.id
    if (audio_id) {
      window.open(`${urlProxy}v1/media/${audio_id}`, '_blank')
    }
  }

  openDescription = (index: number) =>
    this.setState({ showDescription: this.state.showDescription === index ? undefined : index })

  render() {
    const { properties, data, getDefaultValue, entity, language } = this.props
    const { showDescription } = this.state
    const mainProperty = properties.filter(
      (p) => (!p.includes('description') || p === 'description') && p !== 'texthtml'
    )
    const descriptionProperty = properties.filter(
      (p) => (p.includes('description') || p === 'texthtml') && p !== 'description'
    )
    return (
      <Grid fluid>
        {data.map((dataElement, index) => (
          <div className="col-md-12" key={index}>
            <Card
              title={
                <View full row>
                  {entity != 'media' ? (
                    <View full>{dataElement.name || dataElement.title || '---'}</View>
                  ) : (
                    <PreviewImage id={dataElement.id} height={'200px'} />
                  )}
                  {descriptionProperty && descriptionProperty.length > 0 && (
                    <View row center style={{ border: '0.5px solid lightgrey', padding: 4, marginRight: 4 }}>
                      <DescriptionIcon style={{ fontSize: 20 }} onClick={() => this.openDescription(index)} />
                    </View>
                  )}
                  {entity === 'track' && (
                    <View
                      row
                      title={
                        dataElement.media.find((m: Media) => m.type === 'audio' && m.language === language)?.path || ''
                      }
                    >
                      <View
                        onClick={(e) => this.handleFileSelect(e, dataElement.id)}
                        center
                        style={{ border: '0.5px solid lightgrey', padding: 4 }}
                      >
                        <AudiotrackIcon style={{ fontSize: 20 }} />
                        /
                        <VideoIcon style={{ fontSize: 20 }} />
                      </View>
                      <View style={{ width: 4 }} />
                      <View
                        onClick={() => this.playMedia(dataElement)}
                        center
                        style={{
                          border: `0.5px solid ${
                            dataElement.media.find(
                              (m: any) => (m.type === 'audio' || m.type === 'video') && m.language === language
                            )
                              ? 'lightgrey'
                              : 'red'
                          }`,
                          padding: 4,
                        }}
                      >
                        <PlayArrowIcon style={{ fontSize: 20 }} />
                      </View>
                    </View>
                  )}
                </View>
              }
              content={
                <>
                  <TranslationFormInput
                    ncols={mainProperty.map((p) => (p.includes('description') ? 'col-md-12' : 'col-md-6'))}
                    properties={mainProperty.map((property) => {
                      return {
                        label: property,
                        componentClass:
                          property.includes('description') && property !== 'description' ? 'html' : undefined,
                        defaultValue: getDefaultValue(dataElement.id, entity, property),
                        onCustomChange: (newValue: string) => this.onCustomChange(newValue, property, dataElement.id),
                      } as CustomFormControlProps
                    })}
                    data={data}
                  />
                  {showDescription === index && (
                    <TranslationFormInput
                      ncols={descriptionProperty.map((p) =>
                        p.includes('description') || p.includes('texthtml') ? 'col-md-12' : 'col-md-6'
                      )}
                      properties={descriptionProperty.map((property) => {
                        return {
                          label: property,
                          componentClass:
                            (property.includes('description') || property.includes('texthtml')) &&
                            property !== 'description'
                              ? 'html'
                              : undefined,
                          defaultValue: getDefaultValue(dataElement.id, entity, property),
                          onCustomChange: (newValue: string) => this.onCustomChange(newValue, property, dataElement.id),
                        } as CustomFormControlProps
                      })}
                      data={data}
                    />
                  )}

                  <div className="clearfix" />
                </>
              }
            />
          </div>
        ))}
      </Grid>
    )
  }
}
