import React, { Component } from 'react'
import { View, PreviewImage, CustomDropZone } from '.'
import { DropEvent } from 'react-dropzone'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Media } from 'types'
import { AppStore } from 'utils'

interface Props {
  onFileSelected?<T extends File>(acceptedFiles: T[], rejectedFiles?: T[], event?: DropEvent): void
  onDeleteImage?: (media: Media) => void
  medias: Media[]
  fileType: string
}

interface State {
  tabIndex: number
  medias: Media[]
  height?: number
}

export default class UploadImageBox extends Component<Props, State> {
  state: State = {
    tabIndex: 0,
    medias: this.props.medias,
  }

  onFileSelect = (media: any[], type: any, tabIndex: number) => {
    const { fileType } = this.props
    const { medias } = this.state
    if (media.filter((media) => media.name.split('.').pop() != fileType).length > 0) {
      AppStore.notify(`Utilizzare solo file ${fileType}`, 'error')
      return
    }
    if (type.type !== 'multi') {
      medias[tabIndex].media = media[0] as any
    } else {
      medias[tabIndex].multi = []
      media.map((m, index) => {
        medias[tabIndex].multi?.push({
          id: undefined,
          type: `multi_${index + 1}`,
          media: m,
          mediable_id: medias[tabIndex].mediable_id,
          mediable_type: medias[tabIndex].mediable_type,
        })
      })
    }
    if (medias[tabIndex].multi != undefined) {
      this.setState({ medias })
      this.props.onFileSelected && this.props.onFileSelected(media)
    }
  }

  render() {
    const { fileType } = this.props
    const { medias } = this.state
    return (
      <View>
        <Tabs selectedIndex={this.state.tabIndex} onSelect={(tabIndex) => this.setState({ tabIndex })}>
          <TabList>
            {medias.map((media, index) => (
              <Tab key={index}>{media.type}</Tab>
            ))}
          </TabList>
          {medias.map((type, tabIndex) => (
            <TabPanel key={tabIndex}>
              <View row>
                {type.type === 'multi' ? (
                  <View style={{ width: '400px', height: '400px', maxHeight: '400px', overflowY: 'scroll' }}>
                    {type.multi?.map((media, index) => (
                      <PreviewImage
                        key={index}
                        height={'200px'}
                        style={{ border: '1px solid #ccc' }}
                        src={
                          media.media && typeof media.media !== 'string' ? URL.createObjectURL(media.media) : undefined
                        }
                        id={media.id && typeof media.id === 'string' ? media.id : undefined}
                        // onClick={() => onDeleteImage && onDeleteImage(media)}
                      />
                    ))}
                  </View>
                ) : (
                  <PreviewImage
                    src={type.media && typeof type.media !== 'string' ? URL.createObjectURL(type.media) : undefined}
                    id={type.id && typeof type.id === 'string' ? type.id : undefined}
                  />
                )}
                <CustomDropZone
                  fileType={fileType}
                  multiple={type.type === 'multi'}
                  onFileSelected={(media) => this.onFileSelect(media, type, tabIndex)}
                />
              </View>
            </TabPanel>
          ))}
        </Tabs>
      </View>
    )
  }
}
