import { Media } from 'types'

export { default as AppStore } from './AppStore'

export const getLocalStorage = (key: string, defaultValue?: any) => {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState == null) {
      return defaultValue
    }
    return JSON.parse(serializedState)
  } catch (error) {
    console.log('Load error', error)
    return defaultValue
  }
}

export const saveLocalStorage = (key: string, value: any) => {
  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    console.log('Save error', error)
  }
}

export const normalizeMedia = (entity: any, mediable_type: string, expectedType: string[]) => {
  let multiMedia = entity.media.filter((m: Media) => m.type?.includes('multi'))

  if (multiMedia.length > 0) {
    entity.media.push({
      id: undefined,
      type: 'multi',
      mediable_id: entity.id,
      mediable_type: mediable_type,
      multi: multiMedia,
      multi_old: multiMedia,
    } as Media)
  }
  let normMedias: any[] = []

  expectedType.map(e => {
    let med = entity.media.find((media: Media) => media.type === e)
    if (!med) {
      normMedias.push({
        id: undefined,
        type: e,
        mediable_id: entity.id,
        mediable_type: mediable_type,
      } as Media)
    } else {
      normMedias.push(med)
    }
    return e
  })

  entity.media = normMedias

  return entity
}

export const __DEV__ = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
