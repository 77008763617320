import React, { Component } from 'react'
import { Project, Track, Media } from 'types'
import { Grid, Col, Row, Modal, FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import Button from 'components/CustomButton/CustomButton'
import { __, T } from 'config/i18n'
import { CustomFormControlProps } from 'components/FormInputs/FormInputs'
import TableView from 'views/common/TableView'
import { Tracks, Projects, Medias } from 'api'
import { MUIDataTableColumnDef, MUIDataTableOptions } from 'mui-datatables'
import { View, UploadImageBox } from 'components/Common'
import { normalizeMedia } from 'utils'

interface Props {
  project: Project
  refreshCallback: () => void
}
interface State {
  showAddTrackModal: boolean
  track: Partial<Track>
  quantity: number
  showImageModal: boolean
  showIndexModal: boolean
  refreshTable: boolean
}

class TrackTable extends TableView {
  title = __(T.title.tracks)
  resource = new Tracks()
  columns = [
    { label: __(T.form.title), name: 'title' },
    { label: __(T.form.index), name: 'index' },
    { label: __(T.form.search_index), name: 'search_index' },
    { label: __(T.form.visual_index), name: 'visual_index' },
  ]

  options: MUIDataTableOptions = {
    selectableRows: 'none',
    rowsPerPage: 100,
  }

  defaultColumns: MUIDataTableColumnDef[] = [this.editableColumn, this.imageableColumn, this.deletableColumn]
}

export default class ProjectTrackTab extends Component<Props, State> {
  TrackResource = new Tracks()
  trackTable!: any
  state: State = {
    refreshTable: false,
    showImageModal: false,
    showAddTrackModal: false,
    showIndexModal: false,
    track: {},
    quantity: this.props.project.tracks.length,
  }

  properties: CustomFormControlProps[] = [
    {
      label: 'id',
      disabled: true,
    },
    {
      label: 'title',
      required: true,
    },
  ]

  onDetailClick = (item?: Track) => {
    console.log(item)
    this.setState({ track: item || {} })
    if (item) {
      this.onIndexClick(item)
    } else {
      this.handleAddTrackModalShow()
    }
  }

  handleAddTrackModalClose = async () => await this.setState({ showAddTrackModal: false })
  handleAddTrackModalShow = () => this.setState({ showAddTrackModal: true })
  handleIndexModalShow = () => this.setState({ showIndexModal: false })

  saveClose = async () => {
    const { project, refreshCallback } = this.props
    const { track } = this.state

    if (!track) return
    let res
    if (track.id) {
      res = await this.TrackResource.edit(track.id, track)
    } else {
      track.project_id = project.id!
      res = await this.TrackResource.save(track)
    }
    if (res.success) {
      await this.setState({ showAddTrackModal: false })
      await refreshCallback()
    }
  }

  onTracksAdd = async (e: any) => {
    const { project } = this.props
    e.preventDefault()
    let res = await new Projects().changeTrackQuantity(project.id!, this.state.quantity)
    if (res.success) {
      await this.handleAddTrackModalClose()
      this.props.refreshCallback()
    }
  }

  onIndexChange = async (e: any) => {
    const { track } = this.state
    e.preventDefault()
    let res = await new Tracks().changeIndexValue(track.id, track.index, track.visual_index, track.search_index)
    if (res.success) {
      await this.handleIndexModalShow()
      await this.setState({ refreshTable: true })
      await this.props.refreshCallback()
      await new Promise((resolve) => setTimeout(resolve, 300))
      await this.setState({ refreshTable: false })
      // window.location.reload()
    }
  }

  onMediaSave = async () => {
    const { project } = this.props
    const { track } = this.state

    let promises = track.media
      ?.filter((m) => (m.media && typeof m.media !== 'string') || (m.type === 'multi' && m.multi && m.multi.length > 0))
      .map(async (x) => {
        if (x.type == 'multi') {
          x.multi_old?.map(async (m) => await Medias.delete(m))
          x.multi?.map(async (m) => (m.id = (await Medias.upload(project.id!, m)).id!))
        } else {
          x.id = (await Medias.upload(project.id!, x)).id!
        }
      })
    promises && (await Promise.all(promises))

    this.setState({ showImageModal: false })
  }

  addTracksModal = () => (
    <Modal
      {...(this.props as any)}
      show={this.state.showAddTrackModal}
      dialogClassName={'mini-modal'}
      onHide={this.handleAddTrackModalClose}
    >
      <form onSubmit={this.onTracksAdd}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-lg">Quantità tracce del progetto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Tracce totali</ControlLabel>
                  <FormControl
                    type={'number'}
                    defaultValue={this.state.quantity}
                    placeholder={'Tracce totali'}
                    //@ts-ignore
                    onChange={(e) => this.setState({ quantity: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="warning" fill onClick={this.handleAddTrackModalClose}>
            Chiudi
          </Button>
          <Button bsStyle="info" fill type={'submit'}>
            Salva
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )

  addIndexModal = () => {
    var { track } = this.state
    return (
      <Modal
        {...(this.props as any)}
        show={this.state.showIndexModal}
        dialogClassName={'mini-modal'}
        onHide={this.handleIndexModalShow}
      >
        <form onSubmit={this.onIndexChange}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-lg">Search Index e Visual Index</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid fluid>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Index</ControlLabel>
                    <FormControl
                      defaultValue={track.index}
                      placeholder={'Index'}
                      //@ts-ignore
                      onChange={(e: any) => {
                        track.index = e.target.value
                        this.setState({ track })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Search Index</ControlLabel>
                    <FormControl
                      defaultValue={track.search_index}
                      placeholder={'Search Index'}
                      //@ts-ignore
                      onChange={(e: any) => {
                        track.search_index = e.target.value
                        this.setState({ track })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <ControlLabel>Visual Index</ControlLabel>
                    <FormControl
                      defaultValue={track.visual_index}
                      placeholder={'Visual Index'}
                      //@ts-ignore
                      onChange={(e: any) => {
                        track.visual_index = e.target.value
                        this.setState({ track })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="warning" fill onClick={this.handleIndexModalShow}>
              Chiudi
            </Button>
            <Button bsStyle="info" fill type={'submit'}>
              Salva
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }

  onImageableClick = (item?: Track) => {
    this.setState({ track: item!, showImageModal: true })
  }

  onIndexClick = (item?: Track) => {
    this.setState({ track: item!, showIndexModal: true })
  }

  deleteMedia = async (media: Media) => {
    const { track } = this.state
    if (await Medias.delete(media)) {
      track.media = track.media?.filter((m) => m.id !== media.id)
      this.setState({})
    }
  }

  render() {
    const { project } = this.props
    const { showIndexModal, showAddTrackModal, track, showImageModal, refreshTable } = this.state

    return (
      <>
        {!refreshTable && (
          <TrackTable
            data={project.tracks}
            onDetailClick={this.onDetailClick}
            onImageableClick={this.onImageableClick}
            title={`${__(T.title.tracks)} (${project.tracks.length})`}
            {...(this.props as any)}
          />
        )}
        {showAddTrackModal && this.addTracksModal()}
        {showImageModal && track.media ? (
          <Modal
            dialogClassName="custom-dialog"
            show={showImageModal}
            onHide={() => this.setState({ showImageModal: false })}
          >
            <Modal.Body>
              <UploadImageBox
                fileType={'jpg'}
                medias={normalizeMedia(track, 'track', ['multi']).media}
                onDeleteImage={this.deleteMedia}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="warning" fill onClick={() => this.setState({ showImageModal: false })}>
                Chiudi
              </Button>
              <Button bsStyle="info" fill type={'submit'} onClick={this.onMediaSave}>
                Salva
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <View></View>
        )}
        {showIndexModal && this.addIndexModal()}
      </>
    )
  }
}
