import i18n from 'i18next'
import en from './translations/en'
import it from './translations/it'
import { getLocalStorage, saveLocalStorage } from 'utils'

// initialize i18next with catalog and language to use
i18n.init({
  resources: {
    en: { translation: en },
    it: { translation: it },
  },
  lng: getLocalStorage('duva-language', 'it'),
})

let TRANSLATION_MAP = it

// replace object values with keys
Object.keys(it).forEach((catKey, idx) => {
  const subCat = {}
  Object.keys(it[catKey]).forEach((key) => {
    subCat[key] = catKey + '.' + key
  })

  if (idx === 0) {
    TRANSLATION_MAP = {}
  }

  TRANSLATION_MAP[catKey] = subCat
})

export { TRANSLATION_MAP as T }

export const __ = (...args) => i18n.t(...args)

export const changeLanguage = () => {
  const lang = getLanguage() === 'en' ? 'it' : 'en'
  saveLocalStorage('duva-language', lang)
  // i18n.changeLanguage(lang)
  window.location.reload()
}

export const getLanguage = () => i18n.language
